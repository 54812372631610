<template>
  <div>
      <div class="checkMenu">
          <div class="container">
                <div class="tab" :class="{active : checkMenuIndex == 1}" @click="checkMenuIndex = 1">论文查重</div>
                <div class="tab" :class="{active : checkMenuIndex == 2}" @click="checkMenuIndex = 2">查询报告</div>
                <!-- <div class="tab" :class="{active : checkMenuIndex == 3}" @click="checkMenuIndex = 3">常见问题</div> -->
          </div>
      </div>
      
      <div class="container" style="margin-bottom: 50px;">
        <a-row>
            <a-col :md="24" :lg="17" :xl="17">
                <inputFormVue v-if="checkMenuIndex == 1"></inputFormVue>
                <reportVue v-if="checkMenuIndex == 2" :orderNo = 'orderNo'></reportVue>
                <faqVue v-if="checkMenuIndex == 3"></faqVue>
            </a-col>
            <a-col :md="0" :lg="1" :xl="1"></a-col>
            <a-col :md="24" :lg="6" :xl="6">
                <div class="checkSide">
                    <div class="sideTitle">系统检测文献库</div>
                    <ul>
                        <li v-for="(item, index) in checkSide" :key="index">
                            <router-link to=""><img src="../../assets/images/icon01.png" /><p>{{item.title}}</p></router-link>
                        </li>
                    </ul>
                </div>
            </a-col>
        </a-row>
      </div>
  </div>
</template>

<script>
import inputFormVue from './components/inputForm.vue'
import reportVue from './components/report.vue'
import faqVue from './components/faq.vue'
export default {
  components: {
      inputFormVue,
      reportVue,
      faqVue
  },
  data () {
    return {
        checkMenuIndex: 1,
        checkSide: [
            {title: '中国学术期刊数据库'},
            {title: '中国标准全文数据库'},
            {title: '中国优秀报纸全文数据库'},
            {title: '中国专利文献全文数据库'},
            {title: '中国博士学位论文全文数据库'},
            {title: '中国优秀硕士学位论文全文数据'},
            {title: '国内外重要学术会议论文数据库'},
            {title: '博士/优先出版论文数据库'},
            {title: '互联网学术资源数据库'},
            {title: '学术网络文献数据库'},
        ],

        orderNo: '',
    }
  },
  created() {
    // console.log(this.$route.query);
    if(this.$route.query.index){
        this.checkMenuIndex = this.$route.query.index
        this.orderNo = this.$route.query.orderNo
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.checkMenu{width: 100%;height: 54px;background: #1353B4;margin-bottom: 30px;
    .tab{font-size: 16px;font-family: 'PingFangSC-Regular, PingFang SC';color: #B8D3FB;line-height: 54px;display: inline-block;margin-right: 30px;cursor: pointer;}
    .tab.active{color: #ffffff;position: relative;}
    .tab.active::after{content: '';width: 0;height: 0;border-top: 10px solid #1353B4;border-left: 10px solid transparent;border-right: 10px solid transparent;position: absolute;top: 54px;left: calc(50% - 10px);}
}

.checkSide{width: 100%;background: #ffffff;border-radius: 4px;padding: 20px 0;
    .sideTitle{padding: 0 20px 0 40px;font-size: 16px;font-family: 'PingFangSC-Medium, PingFang SC';font-weight: 500;color: #333333;line-height: 50px;position: relative;border-bottom: 1px solid #EFEFEF;}
    .sideTitle::before{content: '';width: 3px;height: 14px;background: #1353B4;position: absolute;left: 20px;top: 18px;}
    ul{padding: 10px 20px;
        li{height: 28px;line-height: 28px;
            a{text-decoration: none;width: 100%;height: 100%;display: flex;align-items: center;
                img{width: 14px;height: 14px;margin-right: 8px;}
                p{font-size: 14px;color: #333333;line-height: 28px;}
            }
            
        }
    }
}
</style>
