<template>
  <div>
      <div class="searchBox">
        <div class="title">订单编号</div>
        <div class="search">
          <input type="text" placeholder="请输入订单编号" v-model="orderNoInput">
          <button @click="toQueryCheckOrder()">查询</button>
        </div>
        <!-- <div class="searchTips">
          <div class="tipsItem"><span class="iconfont">&#xe676;</span><p>查看订单编号方式</p></div>
          <div class="tipsItem"><span class="iconfont">&#xe675;</span><p>支付宝订单</p>
            <div class="tip"><p>我的-账单-找到刚支付的订单-点击查看详情-商家订单号</p><img src="../../../assets/images/orderTip01.png" alt=""></div>
          </div>
          <div class="tipsItem"><span class="iconfont">&#xe678;</span><p>微信订单</p>
            <div class="tip"><p>微信支付-找到刚支付的订单-点击查看详情-商户单号</p><img src="../../../assets/images/orderTip02.png" alt=""></div>
          </div>
        </div> -->
      </div>

      <div class="result">
        <div class="noResult" v-if="resultList.length == 0">
          <img src="../../../assets/images/none.png" alt="">
          <p>没有查询出报告！</p>
        </div>
        <div class="resultTable" v-else>
          <el-table :data="resultList" style="width: 100%">
            <el-table-column
              prop="title"
              label="论文题目"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="author"
              label="论文作者"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="orderStatus" label="状态" >
              <template slot-scope="scope">
                <!-- <span v-if="scope.row.orderStatus=='21'" class="resultStatus resultStatus1">检测完成</span> -->
                <!-- <span v-if="scope.row.orderStatus=='2'" class="resultStatus resultStatus2">检测失败</span> -->
                <!-- <span v-if="scope.row.orderStatus=='12'" class="resultStatus resultStatus3">检测中</span> -->
                <span
                  class="stausCell"
                  :class="{'stausCell1':scope.row.orderStatus=='10','stausCell2':scope.row.orderStatus=='11' || scope.row.orderStatus=='20','stausCell5':scope.row.orderStatus=='21','stausCell4':scope.row.orderStatus=='40','stausCell3': scope.row.orderStatus=='12'}"
                >
                  {{scope.row.statusText}}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="repetitionRateText"
              label="重复率"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="操作" fixed="right" >
              <template slot-scope="scope">
                <!-- <router-link class="downloadBtn" v-if="scope.row.orderStatus=='21'" :to="{path:'/DuplicateCheck/detail',query: {comparison: scope.row.reportInfo.comparison}}"> 查看报告 </router-link>
                <el-button class="downloadBtn2" v-if="scope.row.orderStatus!='21'" disabled>查看报告</el-button> -->

                <el-button class="downloadBtn" v-if="scope.row.orderStatus=='21'" @click="download(scope.row.reportInfo.zip)">下载报告</el-button>
                <!-- <el-button class="uploadBtn" v-if="scope.row.status=='2'">重新提交</el-button> -->
                <el-button class="downloadBtn2" v-if="scope.row.orderStatus !='21'" disabled>下载报告</el-button>
                <!-- <el-button class="delBtn" @click="delReport(scope.row)"  v-if="scope.row.orderStatus =='21'">删除</el-button>
                <el-button class="delBtn2" @click="delReport(scope.row)"  v-if="scope.row.orderStatus !='21'" disabled>删除</el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
  </div>
</template>

<script>

import {queryCheckOrder} from '@/api/EvidenceService'
export default {
  components: {},
  props: {
    orderNo: String,
  },
  data () {
    return {
      resultList: [],
      // orderNo:'900-8358392-594500',
      orderNoInput:'',
    }
  },
  created() {
    if(this.orderNo){
      this.orderNoInput = this.orderNo;
      this.toQueryCheckOrder();
    }
  },
  methods: {
    getStatus(code){
        switch(code){
          case '10':return '待付款';
          case '11':return '待处理';
          case '12':return '检测中';
          case '20':return '待处理';
          case '21':return '检测完成';
          case '31':return '失败';
          case '40':return '已取消';
        }
    },
    toQueryCheckOrder(){
      this.resultList = [];
      queryCheckOrder(this.orderNoInput).then(({data})=>{
          console.log('查询查重结果',data);
          data.statusText=this.getStatus(data.orderStatus);
          data.repetitionRateText = (data.repetitionRate * 100).toFixed(2) + "%"
          this.resultList.push(data);
      });
    },

    //  下载报告
    download(zip){
      window.open(zip, "_self");  //_self，是不用打开新窗口
    },
    
  },
}
</script>

<style lang="scss" scoped>
  .searchBox{width: 100%;background: #ffffff;border-radius: 4px;padding: 20px;
    .title{padding-left: 20px;font-size: 16px;font-family: 'PingFangSC-Medium, PingFang SC';font-weight: 500;color: #333333;line-height: 50px;position: relative;}
    .title::before{content: '';width: 3px;height: 14px;background: #1353B4;position: absolute;left: 0px;top: 18px;}
    .search{display: flex;align-items: center;
      input{width: calc(100% - 90px);height: 48px;line-height: 48px;font-size: 14px;border: 1px solid #D2DCE6;padding: 0 20px;outline: none;border-radius: 4px 0 0 4px;}
      button{width: 88px;height: 48px;line-height: 48px;font-size: 14px;color: #ffffff;background: #316EF4;font-weight: 500;border-radius: 0 4px 4px 0;outline: none;border: none;cursor: pointer;}
    }
    .searchTips{background: #FFFBE6;border-radius: 2px;border: 1px solid #FFE58F;height: 32px;line-height: 32px;display: flex;align-items: center;margin-top: 10px;width: fit-content;position: relative;
      .tipsItem{display: flex;align-items: center;margin-right: 20px;
        .iconfont{margin-right: 6px;}
        p{font-size: 14px;font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';color: #666666;}
        p:nth-child(4),p:nth-child(6){cursor: pointer;}
        .tip{display: none;background: #FFFFFF;box-shadow: 0px 9px 28px 8px rgba(0,0,0,0.05), 0px 6px 16px 0px rgba(0,0,0,0.08), 0px 3px 6px -4px rgba(0,0,0,0.12);border-radius: 8px;position: absolute;
          top: 42px;left: 180px;padding: 20px;width: 560px;z-index: 99;
          p{width: 100%;height: 32px;line-height: 32px;background: #F8F8F8;font-size: 14px;font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';color: #E97B36;padding: 0 10px;margin-bottom: 10px;}
          img{width: 100%;}
        }
        .tip::before{content: '';width: 0;height: 0;border-bottom: 10px solid #ffffff;border-left: 10px solid transparent;border-right: 10px solid transparent;position: absolute;top: -10px;left: 40px;}
      }
      .tipsItem:nth-child(3) .tip::before{left: 160px;}
      .tipsItem:first-child{margin-left: 20px;}
      .tipsItem:first-child .iconfont{font-size: 16px;color: #FAAD14;}
      .tipsItem:nth-child(2) .iconfont{font-size: 24px;color: #06B3FC;}
      .tipsItem:nth-child(3) .iconfont{font-size: 20px;color: #43C93E;}
      .tipsItem:nth-child(2),.tipsItem:nth-child(3){cursor: pointer;}
      .tipsItem:nth-child(2):hover .tip,.tipsItem:nth-child(3):hover .tip{display: block;}
    }
  }
  .result{margin-top: 24px;width: 100%;background: #ffffff;border-radius: 4px;padding: 20px;
    .noResult{text-align: center;padding: 20px 0;
      img{width: 168px;height: 168px;}
      p{font-size: 14px;font-family: 'SourceHanSansCN-Regular, SourceHanSansCN';color: #636B7E;}
    }
    .resultTable{margin-bottom: 20px;
      .resultStatus{padding-left: 12px;position: relative;}
      .resultStatus::before{content: '';width: 6px;height: 6px;border-radius: 50%;position: absolute;left: 0;top: 7px;}
      // .resultStatus1::before{background: #52C41A;}
      // .resultStatus2::before{background: #FF4D4F;}
      // .resultStatus3::before{background: #1677FF;}
      .downloadBtn,.delBtn{color: #1884FF;background: transparent;}
      .downloadBtn2,.delBtn2{color: #999999;background: transparent;}
      .uploadBtn{color: #FF4D4F;background: transparent;}
      .stausCell{
        display: flex;
        align-items: center;
    }
    .stausCell::before{
        content:'';
        width:6px;
        height: 6px;
        border-radius: 100%;
        margin-right:8px;
    }
    .stausCell1::before{
        background:#E4403C;
    }
    .stausCell2::before{
        background:rgba(0, 18, 22,.2);
    }
    .stausCell3::before{
        background:#007DFF;
    }
    .stausCell4::before{
        background:rgba(0, 18, 22,.2);
    }
    .stausCell5::before{
        background:#52C41A;
    }
    }
  }
  
  /deep/.el-table th.el-table__cell{background: #F0F0F0;}
  /deep/.el-button{border: none;outline: none;background: none;padding: 0;}
</style>
