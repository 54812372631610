<template>
  <div>
      <el-form class="form" ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="查重系统">
              <div class="formTitle">
                <img src="../../../assets/images/wanfangLogo2.png" alt="">
                <p>{{price}}元/千字</p>
              </div>
            </el-form-item>
            <el-form-item label="检测版本" class="is-required">
              <div class="checkType">
                <div v-for="(item,index) in checkTypeList" :key="index" :class="form.checkType-1==index?'active':''" @click="chooseType(item,index)">{{item.goodsName}}</div>
              </div>
            </el-form-item>
            <el-form-item label="论文题目" prop="title">
              <el-input
                  :rows="6"
                  placeholder="请输入论文题目，最多可输30个字符"
                  v-model="form.title"
              ></el-input>
            </el-form-item>
            <el-form-item label="论文作者" prop="author">
              <el-input
                  :rows="6"
                  placeholder="请输入论文作者，最多可输10个字符"
                  v-model="form.author"
              ></el-input>
            </el-form-item>
            <el-form-item label="论文内容" ref="uploader" prop="files">
              <el-radio-group v-model="contentType">
                <el-radio :label="1">上传文档</el-radio>
                <!-- <el-radio :label="2">粘贴文本</el-radio> -->
              </el-radio-group>
              <el-upload v-if="contentType == 1"
                class="commonUpload"
                drag
                :action="uploadUrl"
                :headers="uploadHeaders"
                :on-success="uploadSuccess"
                :on-remove="(file, fileList) => {handleRemove(file, fileList)}"
                :before-upload="uploadBefore"
                multiple
                :limit = '1'
                :on-exceed="handleExceed"
                :file-list="fileList"
              >
                <div class="commonUploadInner">
                  <i class="iconfont iconUpload">&#xe606;</i>
                  <div class="el-upload__text">
                      <div>将文件拖到此处，或<em>点击上传</em></div>
                      <div>仅支持上传doc、docx、txt，不支持PDF格式 ，请确认后再提交，文档大小不超过10M</div>
                      <div>论文命名格式：作者_标题 例：张三_论文标题</div>
                  </div>
                </div>
              </el-upload>
              <el-input v-if="contentType == 2"
                  class="contentTextarea"
                  type="textarea"
                  :rows="6"
                  placeholder="请输入论文正文内容，至少500字"
                  v-model="form.content"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item prop="agreement" style="margin-bottom: 10px;">
              <el-checkbox-group v-model="agreement" >
                <el-checkbox>我已同意《全国学术论文(著作)版权登记服务系统查重平台用户协议》</el-checkbox>
              </el-checkbox-group>
            </el-form-item> -->
            <el-form-item>
              <div class="amount">应付金额：<p>¥ {{form.orderAmount}}</p></div>
            </el-form-item>
            <el-form-item label="支付方式" prop="paymode" class="choosePayStyle is-required">
                <el-radio-group v-model="form.paymode">
                <!-- <el-radio label="vip"> <span class="onlyText">中国知链会员权益支付（当前存证次数可用{{vipInfo?vipInfo.existCount:0}}次）</span> </el-radio> -->
                  <el-radio label="wechat">  <i class="iconfont weChatColor"> &#xe678; </i> <span>微信支付</span></el-radio>
                  <el-radio label="alipay"> <i class="iconfont aliPayColor"> &#xe675; </i> <span>支付宝支付</span></el-radio>
              </el-radio-group>
              <!-- <router-link class="buyVip" :to="{path:'/Vip'}">购买会员</router-link> -->
            </el-form-item>
            <el-form-item prop="agreement" style="margin-bottom: 30px;">
              <el-checkbox-group v-model="agreement" >
                <el-checkbox>我已同意<span>《全国学术论文(著作)版权登记服务系统查重平台用户协议》</span></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-form-item class="submitRow">
              <el-button class="submit" type="submit" @click="onSubmit">提交检测</el-button>
            </el-form-item>
      </el-form>

      <div class="checkTips">
          <div class="title">论文提交须知</div>
          <p>1、论文内容的长度至少{{minWordNum}}字，至多{{maxWordNum}}万字，系统按照Word字数统计里面的字符数 (不计空格) 计算。</p>
          <p>2、系统在检测时会分析论文的前后文关系， 所以请您提交论文的全部内容，如果是非全文的检测将不能保证检测的准确性。</p>
          <p>3、免费版查重仅支持中文的检测，请勿提交其它语种！并且免费版查重不检测图书库、专利库、报纸库、外文库和互联网资源。</p>
          <p>4、为了保证互联网查重的准确性，系统采用实时全网查重，因此无法保证每次互联网查重的结果相同。</p>
          <p>5、此系统一旦提交，开始检测后就产生了成本，概不退款！</p>
      </div>

    <pay-dialog ref="payDialog" payForm="check" @close="onSetPayDialogClose"></pay-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as auth from '../../../utils/auth'
import PayDialog from "../../../components/PayDialog";
import {queryCheckGoodsList,applyCheckOrder} from "@/api/EvidenceService";
export default {
  components: {PayDialog},
  data () {
    return {
      contentType: 1,
      agreement: true,
      checkTypeList: [],
      price: 0,
      maxWordNum: 0,
      minWordNum: 0,
      // https://xxx/v1/check/file/upload
      // Merchant-Id: 5
      // Platform-Subject: 1
      // Product-Mode: READ
      uploadUrl:`${process.env.VUE_APP_BASEURL}/v1/check/file/upload?path=check/file/`,
      uploadHeaders:{ 
        'Authorization': this.$store.getters.token,
        'Product-Mode': "READ",
        'Terminal-Code': "IPCHAIN2"+ this.$store.getters.terminalCode,
        'Terminal-Type': "WEB",
        'Merchant-Id': "5",
        'Platform-Subject':  auth.getMid(),
      },
      // uploadUrl:`${process.env.VUE_APP_BASEURL}/v1/ins/file/upload?path=exist/evidence/files/`,
      // uploadHeaders:{ 
      //   'Authorization': this.$store.getters.token,
      //   'Product-Mode': "READ",
      //   'Terminal-Code': "IPCHAIN2"+ this.$store.getters.terminalCode,
      //   'Terminal-Type': "WEB",
      //   'Merchant-Id': "5"
      // },
      fileList: [],
      form: {
        checkType: 1,
        title: '',
        author: '',
        paymode: "wechat",
        files: [],
        recordCode: "",
        goodsId:1,
        // content: '',
        orderAmount: '0.00',
      },
      rules: {
        title:[
          { required: true,  validator: this.valiWorkName, trigger: "blur" },
        ],
        author:[
          { required: true, message: "请输入论文作者", trigger: "blur" },
        ],
        // content:[
        //   { required: true, message: "请输入论文正文", trigger: "blur" },
        // ],
        files: [
          { required: true, message: "请上传论文", trigger: "blur" },
        ],
        agreement: [
          { required: true, validator: this.valiAgreement, trigger: "change" },
        ],
      },
    }
  },
  computed: {
    ...mapGetters(['mid','userInfo','evidencePaymentPrice','vipInfo',"authInfo"])
  },
  created() {
    this.init();
  },
  methods: {
    init(){
      // 获取查重检测版本列表
      queryCheckGoodsList(1,10).then(({data})=>{
          console.log('获取查重检测版本列表',data);
          this.checkTypeList = data.list
          this.price = data.list[0].price
          this.maxWordNum = data.list[0].maxWordNum;
          this.minWordNum = data.list[0].minWordNum;
        });
    },
    chooseType(item,index){
      this.form.checkType = index + 1;
      this.form.goodsId = item.goodsId;
      this.price = item.price
      this.maxWordNum = item.maxWordNum;
      this.minWordNum = item.minWordNum;
    },

    calAmount() {
      //   console.log("计算金额：calAmount evidencePaymentPrice",this.evidencePaymentPrice)
      // let amount = 0;
      // let registerConfig = this.evidencePaymentPrice.register;
      // amount = registerConfig.amount * 1;
      let amount = 0;
      if(this.form.files.length > 0){
        let wordNum = this.form.files[0].wordNum;
        console.log('上传文件返回的字数wordNum:',wordNum);
        if(wordNum < 1000){
          amount = Number(this.price).toFixed(2)
        }else if(wordNum >= 1000){
          amount = ((wordNum / 1000) * this.price).toFixed(2)
        }
      }else{
        amount = '0.00'
      }

      this.form.orderAmount = amount;
    },
    uploadBefore(file) {
        const NoPassFileTypes=['.doc','.docx','.txt']
        const fileName=file.name
        const fileEnd=fileName.substring(fileName.indexOf("."))
				const isFileTypePass = NoPassFileTypes.includes(fileEnd)
				const isLt500M = file.size / 1024 / 1024 < 15;

				if (!isFileTypePass) {
					this.$message.error('仅支持上传 doc、docx、txt 格式文件');
				}
				if (!isLt500M) {
					this.$message.error('上传文件大小不能超过 15MB!');
				}
				return isFileTypePass&&isLt500M;
			},
    uploadSuccess(response, file, fileList){
        console.log(arguments)
        this.$refs.uploader.clearValidate()
        this.form.files.push(response);
        // console.log("response:",response);
        this.form.recordCode = response.recordCode
        //计算价格
        if(response){
          this.calAmount();
        }
    },
    removeFile(file,fileList){
      let that=this;
      let response=file.response;
      if(response){
        let index=that.form.files.findIndex(t=>{
          return t.sourceCode==response.sourceCode;
        });
        if(index>-1){
          that.form.files.splice(index,1);
        }

      }
      console.log(file,fileList);
      // 更新价格
      this.calAmount();
    },
    handleRemove(file,fileList){
      let that=this;
      for(let i=0;i<this.form.files.length;i++){
        if(file.response.sourceName == this.form.files[i].sourceName){
          // console.log("i-------",i);
          that.form.files.splice(i,1);
          // 更新价格
          this.calAmount();
        }
      }
    },
    handleExceed(){  //超过上传文件数量限制
      this.$message.error('只能上传一个文件');
    },
    // 验证作品名称和上传文件名称一致
    valiWorkName(rule, value, callback){
      if(this.form.title == ""){
        callback(new Error("请输入论文题目"));
        return;
      }
      if(this.form.files.length > 0 && this.form.title != this.form.files[0].sourceName){
        callback(new Error("论文题目须与作品样本文件名称保持一致"));
        return;
      }
      if(this.form.title.length > 50){
        callback(new Error("论文题目不能超过50字"));
        return;
      }
      callback();
    },
    // 自定义验证同意协议
    valiAgreement(rule, value, callback){
      if (!this.agreement) {
        callback(new Error("请勾选同意协议"));
        return;
      }
      callback();
    },

    //提交
    async onSubmit(){
        try {
          const formBaseResult = await this.$refs["form"].validate();
          //其他验证
          // if(!this.agreement){
          //   this.$message.error('请勾选同意协议');
          //   return false;
          // }
        } catch (error) {
          // 有错误,定位到错误
          // eslint-disable-next-line
          console.error(error);
          // this.mixinsScrollView();
          return;
        }
        if (this.Info) { // 如果已经提交过订单了，就阻止重复提交
          this.$refs.payDialog.showDialog(this.Info);
          this.isPayDialogVisible = true;
          return false;
        }
       // 验证成功，提交表单
       applyCheckOrder(this.form).then(({ data }) => {
        // that.order = data;
        console.log("check::::::::::::",data);
        this.Info = data;
        // if (data.paymentStatus == "21") {   // 21是已经支付成功
        if (data.orderStatus == "12") {   // 12是已经支付成功，正在检测中
          this.bus.$emit("refreshVip");
          this.onSetPayDialogClose(true);
        } else {
          this.$refs.payDialog.showDialog(data);
          this.isPayDialogVisible = true;
        }
      });

    },
    onSetPayDialogClose(result) {
      console.log('支付成功result:',result);
      // 如果支付成功，跳转到详情页面
      if (result) {
        this.$router.push({
          path: "/Mine/MyCheck",
          // query: { orderNo: this.Info.orderNo },
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/{
  .el-radio__label,.el-checkbox__label{color: rgba(0,0,0,0.65)!important;}
  .el-checkbox__label span{color: #007DFF;}
  .contentTextarea textarea{height: 200px;}
  .el-upload-dragger{height: 200px;}
  .el-upload{display: block;}
}
.form{background: #ffffff;border-radius: 4px;padding: 40px;
  .formTitle{
    display: flex;align-items: center;height: 40px;line-height: 40px;
    img{width: 125px;height: 30px;margin-right: 20px;}
    p{border-radius: 4px;border: 1px solid #316EF4;font-size: 14px;font-family: 'PingFangSC-Medium, PingFang SC';color: #333333;height: 30px;line-height: 30px;padding: 0 15px;}
  }
  .checkType{
    display: flex;align-items: center;height: 40px;line-height: 40px;
    div{width: 88px;height: 32px;line-height: 32px;text-align: center;background: #ECEFF3;border-radius: 14px;font-size: 16px;font-family: 'PingFangSC-Regular, PingFang SC';color: #89909B;margin-right: 10px;cursor: pointer;}
    div.active{background: #316EF4;color: #ffffff;}
  }
  .el-upload__text div:nth-child(2),.el-upload__text div:nth-child(3){font-size: 14px;font-family: 'PingFangSC-Regular, PingFang SC';color: rgba(0,0,0,0.45);line-height: 22px;}
  .amount{display: flex;align-items: center;font-size: 14px;font-weight: 500;color: #000000;margin-bottom: -20px;
    p{color: #316EF4;font-size: 22px;}
  }
  /deep/.el-radio-group,.el-radio{display: flex;align-items: center;height: 40px;line-height: 40px;}
  /deep/.el-radio__label{display: flex;align-items: center;height: 40px;line-height: 40px;}
  /deep/.el-checkbox-group{line-height: 20px;}
  /deep/.el-checkbox{white-space: inherit;display: flex;align-items: center;}
  .aliPayColor{font-size: 28px;margin-right: 5px;}
  .weChatColor{font-size: 24px;margin-right: 7px;}
  .submit{padding: 0 32px;font-size: 14px;color: #ffffff;background: #316EF4;height: 40px;line-height: 40px;border-radius: 4px;}
}

.checkTips{
  background: rgba(255,231,186,0.2);
  border: 1px solid #FFD591;
  padding: 10px 20px;
  margin-top: 24px;
  .title{
    font-size: 14px;
    font-family: 'PingFangSC-Semibold, PingFang SC';
    font-weight: 500;
    color: rgba(0,18,22,0.65);}
    line-height: 24px;
    margin-bottom: 8px;
  p{
    font-size: 12px;
    font-family: 'PingFangSC-Regular, PingFang SC';
    font-weight: 400;
    color: rgba(0,18,22,0.5);
    line-height: 20px;
  }
}

@media screen and (max-width: 991px){
  .form{padding: 20px;}
  .form .el-radio{margin-right: 20px;}
  .form .checkType div{font-size: 13px;}
}

</style>
