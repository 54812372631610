<template>
  <div>
       常见问题
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {}
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
</style>
